<template>
	<div class="layout--full-page">
		<router-view />
	</div>
</template>

<script>
	export default {
		async beforeCreate() {
			const branding = await this.$store.dispatch("branding");

			if (branding && branding.colors) {
				const root = document.documentElement;

				for (const [key, value] of Object.entries(branding.colors)) {
					root.style.setProperty(`--${key}`, value);
				}
			}
		}
	};
</script>
